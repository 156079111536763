import React from 'react';
import BRAND_CONFIG from '../../BRAND_CONFIG';
import AuthForm from '../../components/AuthForm';

const {
    title, ctaText, ctaLoadingText,
} = BRAND_CONFIG.pages.login;

const LoginPage = () => (
    <AuthForm
        title={title}
        ctaText={ctaText}
        ctaLoadingText={ctaLoadingText}
        submitType="login"
    />
);

export default LoginPage;
